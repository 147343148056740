import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CHeroSlider03,
  CDefinition,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CDefinitionAndBtn,
  CDefinitionAndBtn02,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  CInfinitySlider,
  LContact,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
  CInfinitySlider02,
  CFixedImg
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('genjikoh');

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/genjikoh/genjikoh_logo.svg',
            alt: '',
            widthSp: '252px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/genjikoh/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/genjikoh/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-genjikoh/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh03__sp.png',
                    alt: '',
                  },
                },
              ],
              title: <>美しい景色を眺めながら季節の美味が宿る日本料理を</>,
              text: (
                <>
                  四季折々の情緒を感じる日本庭園を臨み、温かみと落ち着きのあるモダンな空間。
                  <br />
                  ご家族での慶事や会食などにも最適なお座敷の個室をはじめ、
                  <br />
                  日本庭園を臨むテーブル席や天婦羅カウンターなど、ご利用シーンに合わせてくつろぎのひとときを。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh05__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh06__sp.png',
                    alt: '',
                  },
                },
              ],
              text: (
                <>
                  その時季の食材を最大限に引き出し、丹精込めて仕上げた一品一品の、繊細な見た目と彩りの美しさ。
                  <br />
                  料理長が歳時をイメージして作り上げ、日本の美しい四季を感じていただける会席料理をご堪能いただけます。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1 u_mb100"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh07.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh07__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh08.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh08__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh09.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/genjikoh/img_genjikoh09__sp.png',
                    alt: '',
                  },
                },
              ],
              title: <>天婦羅カウンター 松風</>,
              text: (
                <>
                  四季折々の素材の魅力を活かした揚げたてを堪能。
                  <br />
                  職人が目の前で揚げる、カウンターならではの特別な雰囲気をお楽しみください。
                </>
              ),
              content: (
                <CDefinition
                  data={[
                    {
                      title: '料金',
                      text: (
                        <>
                          <span className="u_fwb">ランチ</span>
                          お一人様　5,692円〜
                          <br />
                          <span className="u_fwb">ディナー</span>
                          お一人様　12,650円〜
                        </>
                      ),
                    },
                  ]}
                />
              ),
            }}
          />

          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p>
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'メニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['日本料理 源氏香'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
          <ul className="c_noteList u_mbMedium">
            <li>
              季節により内容が変わります。また、アラカルトのご用意もございます。
            </li>
          </ul>
        </LWrap>
      </section>
      <section className="l_sect u_pb40_sp">
        <LWrap>
          <CSectTitle
            title={{
              en: 'PRIVATE ROOM',
              ja: '個室',
            }}
          />
          <CVariableSlider
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh15.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh16.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh17.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh18.png',
                  alt: '',
                },
              },
            ]}
          />
          <CShopPrivate
            data={shopdata}
            btn={{
              label: 'レストラン個室のご案内',
              link: {
                href: '/restaurants/private/',
              },
            }}
          />
          <CSectTitle
            exClass="u_mt80"
            title={{
              en: 'JAPANESE GARDEN TEA ROOM',
              ja: '日本庭園・茶室「耕雲亭」',
            }}
          />

          {/* <CInfinitySlider02
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh19.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh20.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh19.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/genjikoh/img_genjikoh20.png',
                  alt: '',
                },
              },
            ]}
          /> */}
          
          <CFixedImg
            width={680}
            img={{
              src: '/assets/images/restaurants/genjikoh/img_genjikoh19.png',
              alt: '',
            }}
          />
          <CDefinitionAndBtn02
            contents={
              <>
                日本独自の美意識である建物と庭との「和」を尊重したその佇まいは、ここが都心であることを、暫し、忘れさせてくれることでしょう。
              </>
            }
            btn={{
              label: '茶室「耕雲亭」',
              link: {
                href: '/about/facility/garden/',
              },
            }}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-genjikoh/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
